import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	SypacText,
	SypacTabs,
	SypacButton,
	SypacTabButton,
} from '@sypac/component-library-react';
import { Link, useNavigate } from 'react-router-dom';
import { ProducersProductCard } from '../../../components/ProducersProductCard/ProducersProductCard';
import {
	ProductResponse,
	ProductService,
} from '../../../services/product.services';
import { T, useTranslate } from '@tolgee/react';
import { MutatingDots } from 'react-loader-spinner';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { Tons } from '../../../assets/Tons';

const Products: React.FC = () => {
	const { t } = useTranslate();
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [products, setProducts] = useState<ProductResponse>({
		items: [],
		count: 0,
	});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const navigate = useNavigate();

	const getProducts = useCallback(async () => {
		try {
			setIsLoading(true);
			const resp = await ProductService.getList({
				status: currentTab,
				limit: 100,
				offset: 0,
				role: OrderTarget.producer,
			});
			setProducts(resp.data);
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	}, [currentTab]);

	useEffect(() => {
		getProducts().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab]);

	const noProductsText = useMemo(() => {
		const tabTextMap = {
			all: 'noProductsInStoreYet',
			approved: 'noProductsInApproved',
			pending: 'noProductsInPending',
			rejected: 'noProductsInRejected',
		};
		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab]);

	return (
		<div className="flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
			<div className="sm:flex flex-col gap-y-2 lg:grid grid-cols-12 ml-5 mr-7.5">
				<div className="col-span-9">
					<SypacTabs className="box-border">
						<SypacTabButton
							variant="gradient"
							label={t('products.all', 'All')}
							button-id="all"
							onClick={() => setCurrentTab('all')}
						/>
						<SypacTabButton
							variant="gradient"
							label={t('products.approved', 'Approved')}
							button-id="approved"
							onClick={() => setCurrentTab('approved')}
						/>
						<SypacTabButton
							variant="gradient"
							label={t('products.pendingApproval', 'Pending approval')}
							button-id="pending"
							onClick={() => setCurrentTab('pending')}
						/>
						<SypacTabButton
							variant="gradient"
							label={t('products.rejected', 'Rejected')}
							button-id="rejected"
							onClick={() => setCurrentTab('rejected')}
						/>
					</SypacTabs>
				</div>
				<div className="flex justify-end col-start-11 col-span-2">
					<SypacButton>
						<Link
							to="/products/new/groups"
							className="bg-gradient-primary text-white hover:text-white rounded-lg px-6 py-1.25"
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="products.addNewProduct">Add new product</T>
								</p>
							</SypacText>
						</Link>
					</SypacButton>
				</div>
			</div>

			<div
				className={`flex flex-col gap-7 ml-5 mr-7.5 ${
					products?.items?.length === 0 ? 'h-full' : ''
				}`}
			>
				{products?.items?.map((product, index) => {
					return (
						<div
							key={product.id}
							className={`${
								index === products.items.length - 1 ? 'mb-7.5' : ''
							}`}
						>
							<ProducersProductCard data={product} />
						</div>
					);
				})}

				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					products?.items?.length === 0 && (
						<div className="h-full flex flex-col justify-center items-center gap-[60px]">
							<span className="flex scale-[2.25]">
								<Tons />
							</span>

							<div
								className={`flex flex-col gap-6 ${
									currentTab === 'all' ? 'w-[453px]' : ''
								}`}
							>
								<SypacText variant="body-regular-large">
									<p className="text-2xl leading-7 text-gray-80 text-center">
										<T keyName={`products.${noProductsText}`}>
											{noProductsText}
										</T>
									</p>
								</SypacText>

								{currentTab === 'all' && (
									<>
										<SypacText variant="body-normal-small">
											<p className="text-gray-40 text-center">
												<T keyName="ordersTable.noProductsInStoreYetDescription">
													Once you add a product, it will be sent for approval
												</T>
											</p>
										</SypacText>

										<div className="flex justify-center items-center px-3 py-[11px] border border-solid border-gray-10 rounded-10 bg-gray-10-opacity-50">
											<SypacText variant="body-normal-small">
												<p
													className="text-gray-40 text-center"
													style={{
														whiteSpace: 'break-spaces',
													}}
												>
													<T keyName="products.noteAllAddedProductsWillBeReviewed">
														Note: All added products will be reviewed by the
														SYPAC team before they’re displayed in the store.
													</T>
												</p>
											</SypacText>
										</div>

										<SypacButton
											variant="secondary"
											size="small"
											className="w-full"
										>
											<button
												className="w-full flex py-[11px] border-none rounded-lg bg-primary-violet transition hover:bg-primary-violet/80"
												onClick={() => navigate('/products/new/groups')}
											>
												<SypacText variant="body-normal-medium">
													<p className="text-white">
														<T keyName="products.addNewProduct">
															Add new product
														</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
									</>
								)}
							</div>
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default Products;
