import Api from '../axios/api';
import { ProductInterface } from '../interfaces/product.interface';

interface UpdatedProduct {
	id: number;
	name: string;
	companyId: number;
	sourceProductId: number;
	photoUrl: string;
	pricePerUnit: number;
	location: {
		type: string;
		coordinates: number[];
	};
	availableQuantity: number;
	minOrderQuantity: number;
	createdAt: Date;
	markup: number;
}

interface PostProductResponse {
	data: UpdatedProduct;
}

export interface CreateProductParams {
	photoUrl?: string;
	pricePerUnit: number;
	availableQuantity: number;
	minOrderQuantity: number;
	sourceProductId: number;
	lat?: number;
	long?: number;
	unlimited: boolean;
	countryCode?: string;
	address?: string;
}

interface Translation {
	language: string;
	name: string;
}

export interface DefaultProductParams {
	name: string;
	categoryId: string;
	photoUrl: string;
	countryCode: string;
	size: string;
	type: string;
	unit: string;
	translations: Translation[];
}

export interface RequestProductsParams {
	status: string;
	categoryId?: string | undefined;
	limit: number;
	offset: number;
	role: string;
	coordinates?: number[];
}

export interface RequestProductsAdminParams {
	verifiedBy?: string;
	limit: number;
	offset?: number;
	status?: string;
	companyId?: number;
	sortDirection?: string;
	sortBy?: string;
	range?: number;
	coordinates?: number[];
	ids?: number[];
	categoryId?: string;
	language?: string;
	search?: string;
	countryCode?: string;
}

export interface ProductResponse {
	count: number;
	items: ProductInterface[];
}

export interface ResponseProductListResponse {
	data: ProductResponse;
}

export interface EditProduct {
	vat?: number;
	markup?: number;
	availableQuantity?: number;
	pricePerUnit?: number;
	name?: string;
	address?: string;
	countryCode?: string;
	type?: string;
	lat?: number;
	long?: number;
}

export interface EditMeProduct {
	availableQuantity?: number;
	pricePerUnit?: number;
	unlimited?: boolean;
	minOrderQuantity?: number;
	maxOrderCapacity?: number;
}

export interface ProductEditResponse {
	data: ProductInterface;
}

interface DeleteProductResponse {
	response: string;
}

export class ProductService {
	static createProduct(
		body: CreateProductParams,
	): Promise<PostProductResponse> {
		return Api.post(`/v1/producer/products`, {
			...body,
		});
	}

	static editMeProduct(
		id: number,
		body: EditMeProduct,
	): Promise<ProductEditResponse> {
		return Api.patch(`/v1/producer/products/${id}`, {
			...body,
		});
	}

	static getList(
		query: RequestProductsParams,
	): Promise<ResponseProductListResponse> {
		const { limit, offset, categoryId, coordinates, role, status } = query;
		let params: any = {
			limit,
			language: localStorage.getItem('lang') || 'pl',
			offset,
			status,
		};
		if (status === 'all') {
			delete params.status;
		}

		if (categoryId) {
			params = {
				...params,
				categoryId,
			};
		}
		if (coordinates && coordinates.length) {
			params = {
				...params,
				coordinates,
				range: 100,
			};
		}
		return Api.get(`/v1/${role}/products`, {
			params,
		});
	}

	static getListAdmin(
		query: RequestProductsAdminParams,
	): Promise<ResponseProductListResponse> {
		const {
			limit,
			offset,
			status,
			search,
			verifiedBy,
			countryCode,
			sortDirection,
			sortBy,
		} = query;
		let params: RequestProductsAdminParams = {
			limit,
			language: localStorage.getItem('lang') || 'pl',
			offset,
			status,
			verifiedBy,
			countryCode,
			sortDirection,
			sortBy,
		};
		if (status === 'all') {
			delete params.status;
		}

		if (search?.length) {
			params = {
				...params,
				search,
			};
		}

		if (countryCode === 'all') {
			delete params.countryCode;
		}

		return Api.get(`/v1/admin/products`, {
			params,
		});
	}

	static getAvailableProductsAdmin(
		query: RequestProductsAdminParams,
	): Promise<ResponseProductListResponse> {
		const params = {
			limit: query.limit,
			offset: query.offset,
		};
		return Api.get('/v1/admin/products/templates', { params });
	}

	static actionProduct(
		id: number,
		action: string,
	): Promise<PostProductResponse> {
		return Api.post(`/v1/admin/products/${id}/${action}`);
	}

	static editProduct(
		id: number,
		body: EditProduct,
	): Promise<PostProductResponse> {
		return Api.patch(`/v1/admin/products/${id}`, {
			...body,
		});
	}

	static createProductAdmin(
		body: DefaultProductParams,
	): Promise<PostProductResponse> {
		return Api.post('/v1/admin/products/templates', {
			...body,
		});
	}

	static updateDefaultProduct(
		id: number,
		body: DefaultProductParams,
	): Promise<ProductEditResponse> {
		return Api.patch(`/v1/admin/products/templates/${id}`, {
			...body,
		});
	}

	static deleteDefaultProduct(id: number): Promise<DeleteProductResponse> {
		return Api.delete(`/v1/admin/products/templates/${id}`);
	}
}
