import React, { useCallback, useEffect, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import ProductTabs from './components/ProductTabs/ProductTabs';
import { AvatarItem } from '../../../components/AvatarDropDown/AvatarDropDown.interface';
import AvatarDropDown from '../../../components/AvatarDropDown/AvatarDropDown';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { ProductsTable } from '../../../components/ProductsTable/ProductsTable';
import { MutatingDots } from 'react-loader-spinner';
import Pagination from '../../../components/Pagination/Pagination';
import { ProductInterface } from '../../../interfaces/product.interface';
import { LIMIT } from '../../../constants';
import {
	EditProduct,
	ProductService,
} from '../../../services/product.services';
import CompanyDetails from '../ComanyDetails';
import ProductDetails from './components/ProductDetails/ProductDetails';
import { UsersService } from '../../../services/Users.service';
import ModalDeleteProduct from '../../../components/ModalDeleteProduct/ModalDeleteProduct';
import ModalStoreProduct from '../../../components/ModalStoreProduct/ModalStoreProduct';
import useDebounce from '../../../hooks/useDebounce';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import CustomBookTabs from './components/CustomBookTabs/CustomBookTabs';
import { Sorting } from '../../../components/Sorting/Sorting';
import { SortingItemInterface } from '../../../components/Sorting/Sorting.interface';
import { PlusIcon } from '../../../assets/PlusIcon';
import ModalAddProduct from './components/ModalAddProduct/ModalAddProduct';

const Products: React.FC = () => {
	const { t } = useTranslate();
	const [statusTab, setStatusTab] = useState<string>('all');
	const [avatars, setAvatars] = useState<AvatarItem[]>([]);
	const [products, setProducts] = useState<ProductInterface[]>([]);
	const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
		undefined,
	);
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [tabs, setTabs] = useState([
		{ label: 'All', color: 'white', count: '0', value: 'all' },
		{ label: 'Approved', color: 'green', count: '0', value: 'approved' },
		{ label: 'Pending approve', color: 'yellow', count: '0', value: 'pending' },
		{ label: 'Rejected', color: 'gray', count: '0', value: 'rejected' },
	]);
	const [assigneeId, setAssignee] = useState<string>();
	const [page, setPage] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState<string>('234');
	const [count, setCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<
		ProductInterface | undefined
	>(undefined);
	const [showDetailsProduct, setShowDetailsProduct] = useState<boolean>(false);
	const [showRejectProduct, setShowRejectProduct] = useState<boolean>(false);
	const [showOnStore, setShowOnProduct] = useState<boolean>(false);
	const refs = useRef<(HTMLDivElement | null)[]>([]);
	const search = useDebounce(searchQuery, 500);
	const [countryTab, setCountryTab] = useState<string>('all');
	const sortOptions = [
		{
			title: 'Sort by ID descending',
			value: 'id DESC',
		},
		{
			title: 'Sort by ID ascending',
			value: 'id ASC',
		},
	];
	const [sorting, setSorting] = useState<SortingItemInterface>();
	const [showAddProduct, setShowAddProduct] = useState<boolean>(false);

	const countryTabs = [
		{ key: 'all', label: t('adminProducts.allCountries', 'All countries') },
		{ key: 'POL', label: t('adminProducts.poland', 'Poland'), flag: 'PL' },
		{
			key: 'MDA',
			label: t('adminProducts.moldova', 'Moldova'),
			flag: 'MD',
		},
	];

	const getProductList = useCallback(async () => {
		setLoading(true);
		try {
			const sort = sorting?.value.split(' ');
			const sortDirection = sort?.at(1);
			const sortBy = sort?.at(0);
			const { data } = await ProductService.getListAdmin({
				status: statusTab,
				limit: LIMIT,
				offset: page * LIMIT,
				verifiedBy: assigneeId,
				countryCode: countryTab,
				search,
				sortDirection,
				sortBy,
			});

			setProducts(data.items);
			setCount(data.count);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}, [assigneeId, statusTab, page, search, countryTab, sorting]);

	const getUsers = useCallback(async () => {
		try {
			const users = await UsersService.getUsers({
				limit: 10,
				skip: 0,
				companyId: 'self',
			});

			const avatars: AvatarItem[] = users.data?.items
				.map((user) => {
					return {
						id: user.uid,
						fullName: user?.firstName
							? `${user?.firstName} ${user?.lastName}`
							: user?.name,
					};
				})
				.filter((r) => r.fullName);
			setAvatars(avatars);
		} catch (e) {}
	}, []);

	const getStatistics = useCallback(async () => {
		setLoading(true);
		try {
			const [all, approved, pending, rejected] = await Promise.all([
				ProductService.getListAdmin({
					status: 'all',
					limit: 0,
					countryCode: countryTab,
				}),
				ProductService.getListAdmin({
					status: 'approved',
					limit: 0,
					countryCode: countryTab,
				}),
				ProductService.getListAdmin({
					status: 'pending',
					limit: 0,
					countryCode: countryTab,
				}),
				ProductService.getListAdmin({
					status: 'rejected',
					limit: 0,
					countryCode: countryTab,
				}),
			]);

			setTabs([
				{
					label: 'All',
					color: 'white',
					count: all?.data?.count?.toString() ?? '0',
					value: 'all',
				},
				{
					label: 'Pending approve',
					color: 'yellow',
					count: pending?.data?.count?.toString() ?? '0',
					value: 'pending',
				},
				{
					label: 'Approved',
					color: 'green',
					count: approved?.data?.count?.toString() ?? '0',
					value: 'approved',
				},
				{
					label: 'Rejected',
					color: 'gray',
					count: rejected?.data?.count?.toString() ?? '0',
					value: 'rejected',
				},
			]);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}, [countryTab]);

	useEffect(() => {
		getStatistics().then(() => {});
		getUsers().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, statusTab, countryTab]);

	useEffect(() => {
		getProductList().then(() => {});
	}, [getProductList]);

	const selectStatusTab = (tab: string) => {
		setStatusTab(tab);
		setPage(0);
	};

	const changeAssignee = (uid: string) => {
		const assignee = assigneeId === uid ? undefined : uid;
		setAssignee(assignee);
		setPage(0);
	};

	const rowClick = (product: ProductInterface) => {
		const selectedProduct = products.find((r) => r.id === product.id);
		setSelectedProduct(selectedProduct);
		setShowDetails(false);
		setShowDetailsProduct(true);
	};

	const openCompany = (id: number) => {
		setSelectedCompany(id);
		setShowDetailsProduct(false);
		setShowDetails(true);
	};

	const approveProduct = async () => {
		try {
			if (selectedProduct?.id) {
				setLoading(true);
				await ProductService.actionProduct(selectedProduct?.id, 'approve');
				setSelectedProduct({ ...selectedProduct, status: 'approved' });
				const newProducts = products.map((p) => {
					if (p.id === selectedProduct.id) {
						return { ...p, ...selectedProduct, status: 'approved' };
					}
					return p;
				});
				setProducts(newProducts);
				getStatistics().then(() => {});
			}
		} catch (e) {}
	};

	const checkRejectProduct = async () => {
		setShowRejectProduct(true);
	};

	const rejectProduct = async () => {
		try {
			setShowRejectProduct(false);
			if (selectedProduct?.id) {
				setLoading(true);
				await ProductService.actionProduct(selectedProduct?.id, 'reject');
				setSelectedProduct({ ...selectedProduct, status: 'rejected' });
				const newProducts = products.map((p) => {
					if (p.id === selectedProduct.id) {
						return { ...p, ...selectedProduct, status: 'rejected' };
					}
					return p;
				});
				setProducts(newProducts);
				getStatistics().then(() => {});
			}
		} catch (e) {}
	};

	const editProduct = async (data: EditProduct) => {
		try {
			if (selectedProduct?.id) {
				setLoading(true);
				const resp = await ProductService.editProduct(
					selectedProduct?.id,
					data,
				);
				const updatedProduct = resp.data;
				const newProduct = {
					...selectedProduct,
					...updatedProduct,
					pricePerUnitWithProcent:
						updatedProduct.pricePerUnit +
						(updatedProduct.pricePerUnit * updatedProduct.markup || 0) / 100,
				} as unknown as ProductInterface;
				getStatistics().then(() => {});
				setSelectedProduct(newProduct);
				const items = products.map((r) => {
					if (r.id === selectedProduct?.id) {
						return newProduct;
					}
					return r;
				});
				setProducts(items);
			}
		} catch (e) {}
	};

	const clickOutsideDetails = useCallback((event: MouseEvent) => {
		const mountedRefs = refs.current.filter((ref) => ref !== null);
		const isOutside = mountedRefs.every(
			(ref) => !ref?.contains(event.target as Node),
		);
		if (isOutside) {
			setSelectedProduct(undefined);
			setShowDetailsProduct(false);
			setSelectedCompany(undefined);
			setShowDetails(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => {
			document.removeEventListener('mousedown', clickOutsideDetails);
		};
	}, [clickOutsideDetails]);

	return (
		<>
			<div className="relative flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
				<div className="flex flex-col gap-4 mx-[30px]">
					<div className="flex justify-between">
						<SypacText variant="heading-4">
							<p className="text-gray-80">
								<T keyName="adminProducts.productList">Product list</T>
							</p>
						</SypacText>

						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex gap-3 items-center p-0 bg-transparent group"
								onClick={() => setShowAddProduct(true)}
							>
								<div className="w-[24px] h-[24px] flex justify-center items-center p-0 bg-mountain-meadow rounded-lg transition group-hover:bg-mountain-meadow/80">
									<PlusIcon color="white" />
								</div>

								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 mt-[2px] transition group-hover:text-gray-80/60">
										<T keyName="adminProducts.addProduct">Add product</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>

					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							Ut enim ad minim veniam, quis nostrud exercitation.
						</p>
					</SypacText>
				</div>
				<CustomBookTabs
					tabs={countryTabs}
					activeTab={countryTab}
					onTabChange={setCountryTab}
				/>
				<div className="flex justify-between mx-[30px]">
					<div className="flex items-center gap-7.5 md:flex-col xl:flex-row">
						<ProductTabs
							data={tabs}
							callback={selectStatusTab}
							activeTab={statusTab}
						/>

						{avatars.length ? (
							<div className="flex w-fit h-[44px]">
								<AvatarDropDown items={avatars} onChange={changeAssignee} />
							</div>
						) : null}
					</div>

					<div className="flex items-center gap-[42px] md:flex-col lg:flex-row">
						<Sorting
							options={sortOptions}
							action={(item) => setSorting(item)}
						/>

						<SearchBar
							placeholder={t(
								'adminProducts.searchOrderByIdName',
								'Search order by product ID and product name',
							)}
							onChange={setSearchQuery}
							showButton={false}
							classNames="w-[384px]"
						/>
					</div>
				</div>

				<div className="w-full h-full">
					{loading ? (
						<div className="flex w-full h-full items-center justify-center">
							<MutatingDots
								height="100"
								width="100"
								color="#7693F4"
								secondaryColor="#494C83"
								radius="12.5"
								ariaLabel="mutating-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					) : (
						<div className="h-full relative ml-7.5 mr-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
							<div
								className="w-full h-[calc(100%-52px)] overflow-y-auto scroll-smooth pr-[5px]"
								ref={(el) => (refs.current[0] = el)}
							>
								<ProductsTable
									rows={products}
									rowClick={rowClick}
									search={searchQuery}
									openCompany={openCompany}
									clickedOutside={!showDetailsProduct && !showDetails}
								/>
							</div>
							<div className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
								<div className="flex justify-between items-center h-[51px] px-3">
									<Pagination
										showText={true}
										count={count}
										page={page}
										onClick={(item) => setPage(item)}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			{selectedCompany !== undefined && showDetails ? (
				<div ref={(el) => (refs.current[1] = el)}>
					<CompanyDetails
						onClose={() => setShowDetails(false)}
						companyId={selectedCompany}
						onVerification={() => {
							setShowDetails(false);
						}}
					/>
				</div>
			) : null}
			{selectedProduct !== undefined && showDetailsProduct ? (
				<div ref={(el) => (refs.current[2] = el)}>
					<ProductDetails
						onClose={() => setShowDetailsProduct(false)}
						product={selectedProduct}
						approveProduct={approveProduct}
						rejectProduct={checkRejectProduct}
						viewOnStore={() => setShowOnProduct(true)}
						editProduct={editProduct}
					/>
				</div>
			) : null}
			{showRejectProduct ? (
				<div ref={(el) => (refs.current[3] = el)}>
					<ModalDeleteProduct
						isOpen={showRejectProduct}
						onClose={() => setShowRejectProduct(false)}
						onSubmit={rejectProduct}
					/>
				</div>
			) : null}
			{showOnStore ? (
				<div ref={(el) => (refs.current[4] = el)}>
					<ModalStoreProduct
						isOpen={showOnStore}
						onClose={() => setShowOnProduct(false)}
					/>
				</div>
			) : null}
			<ModalAddProduct
				isOpen={showAddProduct}
				onClose={() => setShowAddProduct(false)}
			/>
		</>
	);
};

export default Products;
