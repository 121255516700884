import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { useGetCategories } from '../../../hooks/use-get-categories';
import '../select-product.scss';
import {
	OrderContext,
	OrderProps,
} from '../../../context/OrderContext/order.context';
import {
	ProductResponse,
	ProductService,
} from '../../../services/product.services';
import { NumericFormat } from 'react-number-format';
import { T } from '@tolgee/react';
import { QuantityPickerContext } from '../../../context/QuantityPickerContext/quantity-picker.context';
import { ListMenu } from '../../../components/ListMenu/ListMenu';
import BackArrow from '../../../assets/BackArrow';
import ProducerCompanyIcon from '../../../assets/ProducerCompanyIcon';
import { Tons } from '../../../assets/Tons';
import { HashtagBox } from '../../../assets/HashtagBox';
import { PriceTag } from '../../../assets/PriceTag';
import { GeoService } from '../../../services/geo.services';
import { OrdersService } from '../../../services/orders.services';
import ClientStoreBreadcrumbs from '../../../components/ClientStoreBreadcrumbs/ClientStoreBreadcrumbs';
import { CompanyService } from '../../../services/company.services';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import { Clock } from '../../../assets/Clock';

const ClientSelectProductDetails: React.FC = () => {
	const { groupId, categoryId, productId } = useParams();
	const [products, setProducts] = useState<ProductResponse>({
		items: [],
		count: 0,
	});
	const [userCountry, setUserCountry] = useState<string>();
	const [coordinates, setCoordinates] = useState<number[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [categories, _, isCategoryLoading] = useGetCategories({
		parentIds: [groupId!],
		coordinates,
		countryCode: userCountry,
	});
	const [activeCategory, setActiveCategory] = useState<string>(categoryId!);
	const [localRecentOrders, setLocalRecentOrders] = useState<OrderProps[]>([]);
	const [groups] = useGetCategories({
		countryCode: userCountry,
	});
	const { order, setOrder } = useContext(OrderContext);
	const { setInputQuantity, setTotalQuantityCounter } = useContext(
		QuantityPickerContext,
	);
	const navigate = useNavigate();

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setUserCountry(
				data.countryCode === 'MD'
					? 'MDA'
					: data.countryCode === 'PL'
					? 'POL'
					: '',
			);
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const getRecentOrders = async () => {
		try {
			const query = {
				limit: 5,
				offset: 0,
			};
			const { data } = await OrdersService.getRecentOrders(query);
			if (data.count) {
				setLocalRecentOrders(data.items.slice(0, 3) || []);
			}
		} catch (e) {}
	};

	useEffect(() => {
		getRecentOrders().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectRecentOrder = (order: OrderProps) => {
		setOrder({ ...order });
		navigate(
			`/store/groups/${order?.group?.id}/categories/${order?.category?.id}/products/${order?.product?.id}/quantity`,
		);
	};

	const getProducts = useCallback(async () => {
		try {
			const resp = await ProductService.getList({
				status: 'all',
				categoryId,
				limit: 100,
				offset: 0,
				role: 'customer',
			});
			setProducts(resp.data);
		} catch (e) {}
	}, [categoryId]);

	useEffect(() => {
		getProducts().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId]);

	const getLocation = useCallback(async (locationId: string) => {
		if (locationId) {
			const resp = await GeoService.getLocationDetails({
				locationid: locationId,
			});
			setCoordinates([resp.data.Longitude, resp.data.Latitude]);
		}
	}, []);

	useEffect(() => {
		if (order?.location?.locationId) {
			getLocation(order?.location?.locationId).then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.location?.locationId]);

	const currentProduct = useMemo(() => {
		return products?.items?.find((item) => item.id === +productId!);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products, categoryId]);
	const currentCategory = useMemo(() => {
		return categories?.items?.find((item) => item.id === categoryId);
	}, [categories, categoryId]);
	const currentGroup = useMemo(() => {
		return groups?.items?.find((item) => item.id === groupId);
	}, [groups, groupId]);

	const listItems =
		categories?.items?.map((item) => {
			const handleClick = () => {
				if (item.id !== activeCategory) {
					setActiveCategory(item.id);
					navigate(`/store/groups/${groupId}/categories/${item.id}/products`);
				}
			};

			return {
				id: item.id,
				name: item.name,
				onClick: handleClick,
			};
		}) || [];

	useEffect(() => {
		setOrder({
			...order,
			// dateFrom: undefined,
			// dateTo: undefined,
			product: currentProduct,
			group: currentGroup,
			category: currentCategory,
		});
		setInputQuantity(0);
		setTotalQuantityCounter(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const next = () => {
		setOrder({
			...order,
			product: currentProduct,
			group: currentGroup,
			category: currentCategory,
		});
		navigate('quantity');
	};

	const renderRecentOrders = (order: OrderProps) => {
		return (
			<button
				key={order?.product?.id}
				className="flex items-center py-2.75 px-3 bg-transparent border-none rounded-lg cursor-pointer p-0 hover:bg-gray-10"
				onClick={() => selectRecentOrder(order)}
			>
				<div
					style={{
						backgroundImage: `url(${order?.product?.photoUrl})`,
					}}
					className="box-border flex flex-none w-[46px] h-[46px] bg-no-repeat bg-cover rounded-[8px] border border-solid border-gray-10"
				/>
				<div className="flex flex-col ml-3 items-start">
					<SypacText variant="body-normal-medium">
						<p
							className={`w-[197px] text-start truncate text-base text-gray-80 ${
								!order?.product?.type && !order?.product?.size
									? 'pb-[20px]'
									: ''
							}`}
						>
							{order?.product?.name}
						</p>
					</SypacText>
					<SypacText variant="body-normal-medium">
						<p className="text-sm text-gray-40">
							{order?.product?.type}{' '}
							{order?.product?.size && `(${order?.product?.size})`}
						</p>
					</SypacText>
				</div>
			</button>
		);
	};

	return (
		<div className="w-full flex flex-col h-[calc(100vh-30px)] overflow-hidden gap-x-2.5 mt-[68px] sm:mt-0">
			<div className="w-[1172px] xl:self-center flex justify-between items-center mb-8">
				<ClientStoreBreadcrumbs
					currentGroup={currentGroup}
					currentCategory={currentCategory}
					categoryId={categoryId}
					currentProduct={currentProduct}
				/>

				{/* <SearchBar
					placeholder={t(
						'clientSelectGroups.searchPlacehoder',
						'Search Products',
					)}
					showButton={false}
				/> */}
			</div>

			<div className="w-full flex border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 pl-[calc(50vw-638px)]">
				<div className="h-fit flex flex-col gap-6 mt-6">
					<div className="mr-4">
						<ListMenu
							items={!isCategoryLoading ? listItems : []}
							selected={categoryId}
						/>
					</div>
					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

					<div className="flex flex-col gap-5">
						<div className="flex gap-3 items-center">
							<Clock />
							<SypacText variant="body-regular-medium">
								<p className="text-base text-gray-40 mt-[2px]">
									<T keyName="clientSelectCategory.recent">Recent</T>
								</p>
							</SypacText>
						</div>

						{localRecentOrders.length ? (
							<div className="w-[255px] flex flex-col">
								{localRecentOrders.map((order: OrderProps) =>
									renderRecentOrders(order),
								)}
							</div>
						) : (
							<div className="w-[232px] flex flex-col gap-6 box-border">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="clientSelectCategory.noRecentProductsPreviewed">
											You haven’t previewed any products recently
										</T>
									</p>
								</SypacText>
								<SypacText variant="body-regular-medium">
									<p className="text-xs text-gray-40">
										<T keyName="clientSelectCategory.noRecentProductsPreviewedDescription">
											Previously viewed products will be listed here
										</T>
									</p>
								</SypacText>
							</div>
						)}
					</div>
				</div>
				<div className="border border-solid border-gray-10 border-b-0 border-t-0 border-r-0" />

				<div className="h-[100vh] overflow-x-hidden scroll-smooth flex-1 pr-[12px]">
					<div className="flex border border-solid border-gray-10 border-t-0 border-l-0 border-r-0 pl-6 py-3">
						<SypacButton
							variant="secondary"
							onClick={() => {
								navigate(-1);
							}}
						>
							<button className="flex gap-6 border-none h-[24px] bg-transparent group">
								<span className="mt-[4px] transition group-hover:text-gray-80 group-hover:-translate-x-[6px] scale-[1.2]">
									<BackArrow />
								</span>
								<SypacText variant="body-normal-medium">
									<p className="text-gray-80">
										<T keyName="clientSelectProductDetails.backToProductList">
											Back to product list
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>

					<div className="flex gap-6 pt-12 mb-[180px]">
						<div className="flex flex-col pl-6">
							<div className="w-[460px] flex flex-col gap-3">
								<div
									style={{
										backgroundImage: `url(${currentProduct?.photoUrl})`,
									}}
									className="w-full h-[240px] rounded-10 border border-solid border-gray-10 bg-no-repeat bg-cover bg-center"
								/>
							</div>
						</div>

						<div className="w-[365px] flex flex-col gap-6">
							<div className="hidden">
								{/* <div className="my-7">
						<SypacText variant="body-normal-medium" className="mb-6">
							<p>
								<T keyName="clientSelectProductDetails.aboutProduct">
									About product
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-normal-medium">
							<p>
								&nbsp; 1 Quarried consists of pure granite or crushed Limestone.
								It is quarry sourced dolomite that complies with the clause 803
								specification for highway works.
							</p>
						</SypacText>
						<SypacText variant="body-normal-medium">
							<p>
								&nbsp; This product is perfect as a top grade aggregate in the
								construction of hard-standings, haul roads, building sub-bases
								and path, road, highway, driveway, patio sub-bases.
							</p>
						</SypacText>
					</div>
					<div>
						<SypacBox className="pt-6 px-6">
							<SypacText
								variant="body-normal-medium"
								className="text-gray-90 mb-5"
							>
								<p>
									<T keyName="clientSelectProductDetails.priceAndQuantity">
										Price and quantity:
									</T>
								</p>
							</SypacText>
							<div className="grid grid-cols-4 gap-14">
								<SypacImage className="cursor-pointer">
									<img
										src="/files/ASME-U-Stamp_11_09_2024-001.jpg"
										alt="truck_1"
									/>
								</SypacImage>
								<SypacImage className="cursor-pointer">
									<img src="/files/images.jpg" alt="truck_2" />
								</SypacImage>
								<SypacImage className="cursor-pointer">
									<img src="/files/images%20(1).jpg" alt="truck_3" />
								</SypacImage>
								<SypacImage className="cursor-pointer">
									<img src="/files/images-1.jpg" alt="truck_4" />
								</SypacImage>
							</div>
						</SypacBox>
					</div> */}
							</div>

							<div className="flex flex-col gap-6 [&_p]:text-gray-80">
								<SypacText variant="body-regular-medium">
									<p className="text-2xl leading-7">{currentProduct?.name}</p>
								</SypacText>
								<SypacText variant="body-regular-small">
									<p className="text-sm">
										{currentProduct?.type}{' '}
										{currentProduct?.size ? `(${currentProduct?.size})` : ''}
									</p>
								</SypacText>
							</div>
							{/* <div className="flex flex-col gap-2">
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40">
										4 — 6{' '}
										<T keyName="clientsProductCard.workingDaysToDelivery">
											working days to delivery
										</T>
									</p>
								</SypacText>
								<div className="flex gap-3 items-center">
									<RatingStars
										score={4}
										stars={[1, 2, 3, 4, 5]}
										disabled={true}
									/>
									<SypacText variant="body-regular-medium">
										<p className="text-xs text-gray-40">4.0</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-xs text-gray-40">
											<T
												keyName="clientsProductCard.basedOnCustomerReviews"
												params={{
													reviews: 25,
												}}
											>
												Based on customer reviews.
											</T>
										</p>
									</SypacText>
								</div>
							</div> */}
							<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

							<div className="flex flex-col gap-3">
								<div className="flex gap-4">
									<span className="scale-[1.2] mt-[2px]">
										<HashtagBox />
									</span>
									<div className="w-full flex justify-between">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												<T keyName="clientsProductCard.productId">Product ID</T>
											</p>
										</SypacText>
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">{currentProduct?.id}</p>
										</SypacText>
									</div>
								</div>
								<div className="flex gap-4">
									<span className="scale-[1.2] mt-[2px]">
										<Tons />
									</span>
									<div className="w-full flex justify-between">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												<T keyName="clientsProductCard.availableQuantity">
													Available quantity
												</T>
											</p>
										</SypacText>
										<SypacText variant="body-normal-medium">
											{currentProduct?.unlimited ? (
												<p className="text-gray-80">
													<T keyName="clientsProductCard.unlimited">
														Unlimited
													</T>
												</p>
											) : (
												<p className="text-gray-80">
													{currentProduct?.availableQuantity || 0}{' '}
													{currentProduct?.unit}
												</p>
											)}
										</SypacText>
									</div>
								</div>
								<div className="flex gap-4">
									<span className="scale-[1.2] mt-[2px]">
										<ProducerCompanyIcon />
									</span>
									<div className="w-full flex justify-between">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												<T keyName="clientsProductCard.productStock">
													Product stock
												</T>
											</p>
										</SypacText>
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												<T keyName="clientSelectProductDetails.available">
													Available
												</T>
											</p>
										</SypacText>
									</div>
								</div>
							</div>
							<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

							<div className="flex flex-col gap-3">
								<div className="flex gap-4">
									<span className="scale-[1.2] mt-[2px]">
										<PriceTag />
									</span>
									<div className="w-full flex justify-between">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												<T keyName="clientSelectProductDetails.pricePerTonExcl">
													Price per ton (excl. VAT)
												</T>
											</p>
										</SypacText>
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator="."
													decimalSeparator=","
													value={
														parseFloat(
															currentProduct?.pricePerUnitWithProcent?.toFixed(
																2,
															)!,
														) || 0
													}
													suffix={
														currentProduct?.currency === 'lei' ? ' MDL' : ' zł'
													}
												/>
											</p>
										</SypacText>
									</div>
								</div>
								<div className="flex gap-2 [&_p]:text-sm [&_p]:text-gray-40">
									<SypacText variant="body-normal-medium">
										<p>
											<T keyName="clientSelectProductDetails.pricePerTonIncl">
												Price per ton (incl. VAT):
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-normal-medium">
										<p>
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={
													parseFloat(
														currentProduct?.pricePerUnitWithVat?.toFixed(2)!,
													) || 0
												}
												suffix={
													currentProduct?.currency === 'lei' ? ' MDL' : ' zł'
												}
											/>
										</p>
									</SypacText>
								</div>
							</div>

							<div className="flex justify-between">
								<button
									onClick={() => next()}
									className="w-full h-[44px] flex items-center rounded-10 cursor-pointer px-3 border-0 bg-gradient-primary text-white justify-center"
								>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="clientSelectProductDetails.orderNow">
												Order now
											</T>
										</p>
									</SypacText>
								</button>

								{/* <div className="w-[44px] h-[44px] flex items-center justify-center border border-solid border-gray-10 rounded-[10px] bg-white">
									<span className="scale-[1.2] mt-[4px]">
										<MarkProductIcon />
									</span>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="relative overflow-hidden w-full my-15">
				<LineStroke width={[958, 958, 976.423]} />
			</div>
			<div>
				<SypacText variant="heading-5">
					<p>
						<T keyName="clientSelectProductDetails.bestsellerThisCategory">
							Bestseller this category
						</T>
					</p>
				</SypacText>
				<div className="mt-7 grid grid-cols-3 sm:grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
					{demoProducts?.items?.map((item) => {
						return (
							<GroupCard
								key={item.id}
								imageUrl={item.photoUrl}
								title={item.name}
								description={`${item.type} ${
									item.size ? `(${item.size})` : ''
								}`}
								subtitle={`Category: ${item.category?.name}`}
							/>
						);
					})}
				</div>
			</div>
			<div className="relative overflow-hidden w-full my-15">
				<LineStroke width={[958, 958, 976.423]} />
			</div>
			<div>
				<SypacText variant="heading-5">
					<p>
						<T keyName="clientSelectProductDetails.basedOnYourViews">
							Based on your views
						</T>
					</p>
				</SypacText>
				<div className="mt-7 grid grid-cols-3 sm:grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
					{demoProducts?.items?.map((item) => {
						return (
							<GroupCard
								key={item.id}
								imageUrl={item.photoUrl}
								title={item.name}
								description={`${item.type} ${
									item.size ? `(${item.size})` : ''
								}`}
								subtitle={`Category: ${item.category?.name}`}
							/>
						);
					})}
				</div>
			</div> */}
		</div>
	);
};

export default ClientSelectProductDetails;
