export const TYPE_TRUCK: { [key: string]: { value: string; label: string }[] } =
	{
		en: [
			{ value: 'Dump Truck', label: 'Dump Truck' },
			{ value: 'Truck Tractor', label: 'Semi-trailer truck' },
		],
		pl: [
			{ value: 'Dump Truck', label: 'Wywrotka' },
			{ value: 'Truck Tractor', label: 'Ciągnik z naczepą' },
		],
		ro: [
			{ value: 'Dump Truck', label: 'Dump Truck' },
			{ value: 'Truck Tractor', label: 'Semi-trailer truck' },
		],
		ru: [
			{ value: 'Dump Truck', label: 'Dump Truck' },
			{ value: 'Truck Tractor', label: 'Semi-trailer truck' },
		],
	};
