import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminGuardedRoute from '../guards/AdminGuardedRoute';
import Dashboard from '../../containers/Admin/Dashboard';
import NotFound from '../../containers/NotFound';
import Orders from '../../containers/Admin/Orders';
import Billing from '../../containers/Admin/Billing';
import Products from '../../containers/Admin/Products';
import { SideMenu } from '../../components/SideBarMenu/SideMenu';

const AdminRouter: React.FC = () => {
	const ADMIN_MENU = [
		{ logo: 'Shield Check', alt: 'Companies', url: '/companies' },
		{
			logo: 'Document Add',
			alt: 'Orders',
			url: '/orders',
			count: true,
		},
		{ logo: 'Money Bag', alt: 'Billing', url: '/billing' },
		{ logo: 'Bag', alt: 'Products', url: '/products' },
	];

	return (
		<Routes>
			<Route
				path="/companies"
				element={
					<AdminGuardedRoute>
						<SideMenu groups={ADMIN_MENU}>
							<Dashboard />
						</SideMenu>
					</AdminGuardedRoute>
				}
			/>
			<Route
				path="/orders"
				element={
					<AdminGuardedRoute>
						<SideMenu groups={ADMIN_MENU}>
							<Orders />
						</SideMenu>
					</AdminGuardedRoute>
				}
			/>
			<Route
				path="/billing"
				element={
					<AdminGuardedRoute>
						<SideMenu groups={ADMIN_MENU}>
							<Billing />
						</SideMenu>
					</AdminGuardedRoute>
				}
			/>
			<Route
				path="/products"
				element={
					<AdminGuardedRoute>
						<SideMenu groups={ADMIN_MENU}>
							<Products />
						</SideMenu>
					</AdminGuardedRoute>
				}
			/>
			<Route path="/*" element={<NotFound />} />
		</Routes>
	);
};

export default AdminRouter;
