import React, { useState } from 'react';
import Modal from 'react-modal';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacButton,
	SypacCheckbox,
	SypacDropdown,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ManageProductProps } from './ManageProduct.interface';
import { useGetProductsTmpl } from '../../hooks/use-get-products-tmpl';
import { EditMeProduct, ProductService } from '../../services/product.services';
import classNames from 'classnames';

const ManageProduct = ({
	isOpen,
	onClose,
	onSubmit,
	product,
}: ManageProductProps) => {
	const [maxStockAvailability, setStockAvailability] =
		useState<number>(1000000);
	const { t } = useTranslate();
	const [disableSend, setDisableSend] = useState<boolean>(false);
	const [products] = useGetProductsTmpl(
		product.categoryId,
		100,
		0,
		'producer',
		product?.countryCode,
	);

	const formik = useFormik({
		initialValues: {
			available: product.availableQuantity,
			availableQuantity: product.availableQuantity,
			unlimited: product.unlimited,
			minOrderQuantity: product.minOrderQuantity,
			maxOrderCapacity: product.maxOrderCapacity,
			pricePerUnit: product.pricePerUnit,
		},
		onSubmit: async (values) => {
			setDisableSend(true);
			const resp = await ProductService.editMeProduct(product.id!, {
				pricePerUnit: values.pricePerUnit,
				minOrderQuantity: values.minOrderQuantity,
				availableQuantity: values.available,
				maxOrderCapacity: values.maxOrderCapacity,
				unlimited: values.unlimited,
			} as EditMeProduct);
			onSubmit(resp.data);
			setDisableSend(false);
		},
		validateOnChange: true,
		validationSchema: Yup.object({
			available: Yup.number()
				.positive(
					t(
						'selectProduct.minimumProductStock',
						'Minimum product stock is 0.1',
					),
				)
				.when(['availableQuantity', 'unlimited'], {
					is: (a: number, b: boolean) => {
						return a !== undefined || !b;
					},
					then: Yup.number().required(
						t(
							'selectProduct.productStockIsRequired',
							'Product stock is required',
						),
					),
				}),
			minOrderQuantity: Yup.number()
				.positive(
					t('selectProduct.minimumCapacityValue', 'Minimum capacity is 0.1'),
				)
				.max(
					maxStockAvailability,
					t(
						'selectProduct.maxMinimumCapacityValue',
						'Maximum order capacity is the product stock available',
					),
				)
				.required(
					t(
						'selectProduct.minimumCapacityIsRequired',
						'Minimum capacity is required',
					),
				),
			maxOrderCapacity: Yup.number()
				.positive(
					t(
						'selectProduct.minimumMaxCapacityValue',
						'Minimum max order capacity is 0.1',
					),
				)
				.max(
					maxStockAvailability,
					t(
						'selectProduct.maximumMaxCapacityValue',
						'Maximum order capacity is the product stock available',
					),
				)
				.required(
					t(
						'selectProduct.maximumCapacityIsRequired',
						'Maximum capacity is required',
					),
				),
			pricePerUnit: Yup.number()
				.positive(
					t('selectProduct.netPriceMinimumValue', 'Minimum Net price is 0.1'),
				)
				.required(
					t('selectProduct.netPriceIsRequired', 'Net price is required'),
				),
		}),
	});

	const changeAvailableStock = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		setStockAvailability(
			formik.values.unlimited ? 1000000 : parseFloat(e.target.value) || 100000,
		);
		if (formik.values.maxOrderCapacity) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
		if (formik.values.minOrderQuantity) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	const changeUnlimited = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		setStockAvailability(
			e.target.checked ? 1000000 : formik.values.available || 100000,
		);
		if (formik.values.maxOrderCapacity) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
		if (formik.values.minOrderQuantity) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	const changeMinOrderQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		const value = parseFloat(e.target.value);
		if (!(value && value > 0.1 && value < maxStockAvailability)) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	const changeMaxOrderCapacity = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		const value = parseFloat(e.target.value);
		if (!(value && value > 0.1 && value < maxStockAvailability)) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="modal-inside max-w-[880px] outline-none"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col w-full">
				<div className="flex flex-row items-center justify-between px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="modalManageProduct.manageProduct">Manage product</T>
						</p>
					</SypacText>
					<SypacIcon
						iconName="Close Square"
						className="text-gray-10 ml-6 cursor-pointer"
						size="custom"
						width="32px"
						height="32px"
						onClick={onClose}
					/>
				</div>
				{products?.items?.length ? (
					<form onSubmit={formik.handleSubmit}>
						<div className="flex flex-col items-center justify-center modal-confirmation-code-border px-6 py-4 gap-4">
							<div className="w-full">
								<SypacText variant="body-regular-medium" className="mt-6.25">
									<p className="text-gray-80 font-medium pb-6.25">
										<T keyName="selectProduct.typeAndAvailability">
											2. Type & availability
										</T>
									</p>
								</SypacText>
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-6.25 pb-2.5">
									<div className="w-full">
										<SypacText variant="overline-normal-large" className="mb-1">
											<p className="-tracking-[0.01em] text-primary-dark-gray">
												<T keyName="selectProduct.productType">Product type</T>{' '}
												<span className="text-red">*</span>
											</p>
										</SypacText>

										<SypacDropdown
											name="sourceProductId"
											placeholder={t(
												'selectProduct.selectProductType',
												'Select Product Type',
											)}
											aria-disabled={true}
											className={classNames({
												'pointer-events-none': true,
												'disabled-drop-down': true,
											})}
										>
											{products?.items?.map((item) => {
												const { sourceProductId } = product;

												const label = `${item.name || ''}${
													item.type ? `, ${item.type}` : ''
												}${item.size ? `: ${item.size}` : ''}`;
												return (
													// @ts-ignore
													<sypac-dropdown-option
														key={item.id}
														value={item.id}
														label={label}
														selected={item.id === sourceProductId}
													/>
												);
											})}
										</SypacDropdown>
									</div>
									<div className="w-full">
										<SypacInput
											error={
												!!(formik.touched.available && formik.errors.available)
											}
											className="mb-1"
										>
											<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
												<T keyName="selectProduct.productStockAvailability">
													Product stock availability (tons)
												</T>
												<span className="text-red">*</span>
											</label>
											<input
												className="py-2.5 pl-3 border-[1px] rounded-md placeholder:text-gray-22"
												name="available"
												type="number"
												placeholder={t(
													'selectProduct.enterAvailableProductStock',
													'Enter available product stock',
												)}
												value={formik?.values.available}
												onChange={changeAvailableStock}
												disabled={formik?.values.unlimited}
											/>
											{formik.touched.available && formik.errors.available ? (
												<span className="bottom-helper">
													<T keyName="selectProduct.errorAvailable">
														{formik.errors.available}
													</T>
												</span>
											) : null}
										</SypacInput>
									</div>
								</div>
								<div className="pb-6.25 grid grid-cols-1 lg:grid-cols-2 gap-6.25">
									<div className="col-start-2 flex items-center gap-4">
										<SypacCheckbox size="md">
											<input
												type="checkbox"
												name="unlimited"
												checked={formik?.values.unlimited}
												onChange={changeUnlimited}
											/>
											{formik.touched.unlimited && formik.errors.unlimited ? (
												<span className="bottom-helper">
													<T keyName="selectProduct.errorUnlimited">
														{formik.errors.unlimited}
													</T>
												</span>
											) : null}
										</SypacCheckbox>
										<SypacText variant="overline-normal-large">
											<p className="text-gray-hover-2 pt-1">
												<T keyName="selectProduct.unlimitedQuantity">
													Unlimited quantity
												</T>
											</p>
										</SypacText>
									</div>
								</div>
							</div>
							<div className="w-full">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 font-medium pb-6.25">
										<T keyName="selectProduct.priceAndOrder">
											3. Price and order
										</T>
									</p>
								</SypacText>
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-6.25 items-end">
									<div className="grid grid-cols-1 md:grid-cols-2 gap-6.25">
										<div className="w-full">
											<SypacInput
												error={
													!!(
														formik.touched.minOrderQuantity &&
														formik.errors.minOrderQuantity
													)
												}
											>
												<SypacText
													variant="overline-normal-large"
													className="mb-1"
												>
													<p className="-tracking-[0.01em] text-primary-dark-gray">
														<T keyName="selectProduct.minimumOrderCapacity">
															Minimum order capacity per day (tons)
														</T>
														<span className="text-red">*</span>
													</p>
												</SypacText>
												<input
													className="py-2.5 pl-3 border-[1px] rounded-md placeholder:text-gray-22"
													type="number"
													name="minOrderQuantity"
													placeholder={t(
														'selectProduct.enterMinimumPerTon',
														'Enter minimum per ton',
													)}
													value={formik?.values.minOrderQuantity}
													onChange={changeMinOrderQuantity}
												/>
												{formik.touched.minOrderQuantity &&
												formik.errors.minOrderQuantity ? (
													<span className="bottom-helper">
														<T keyName="selectProduct.errorMinOrderQuantity">
															{formik.errors.minOrderQuantity}
														</T>
													</span>
												) : null}
											</SypacInput>
										</div>
										<div className="w-full">
											<SypacInput
												error={
													!!(
														formik.touched.maxOrderCapacity &&
														formik.errors.maxOrderCapacity
													)
												}
											>
												<SypacText
													variant="overline-normal-large"
													className="mb-1"
												>
													<p className="-tracking-[0.01em] text-primary-dark-gray">
														<T keyName="selectProduct.maximumOrderCapacity">
															Maximum order capacity per day (tons)
														</T>
														<span className="text-red">*</span>
													</p>
												</SypacText>
												<input
													className="py-2.5 pl-3 border-[1px] rounded-md placeholder:text-gray-22"
													type="number"
													name="maxOrderCapacity"
													placeholder={t(
														'selectProduct.enterMaximumPerTon',
														'Enter maximum per ton',
													)}
													value={formik?.values.maxOrderCapacity}
													onChange={changeMaxOrderCapacity}
												/>
												{formik.touched.maxOrderCapacity &&
												formik.errors.maxOrderCapacity ? (
													<span className="bottom-helper">
														<T keyName="selectProduct.errorMaxOrderCapacity">
															{formik.errors.maxOrderCapacity}
														</T>
													</span>
												) : null}
											</SypacInput>
										</div>
									</div>
									<div className="w-full">
										<SypacText variant="overline-normal-large" className="mb-1">
											<p className="-tracking-[0.01em] text-primary-dark-gray">
												<T keyName="selectProduct.netPricePerTon">
													Net price per ton (excluding VAT)
												</T>
												<span className="text-red">*</span>
											</p>
										</SypacText>
										<SypacInput
											error={
												!!(
													formik.touched.pricePerUnit &&
													formik.errors.pricePerUnit
												)
											}
										>
											<div className="flex border-solid border-[1px] border-gray-22 rounded-md">
												<input
													type="number"
													step="0.01"
													name="pricePerUnit"
													className="block border-0 py-2.5 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
													placeholder={t(
														'selectProduct.enterProductPricePerTon',
														'Enter product price per ton',
													)}
													value={formik?.values.pricePerUnit}
													onChange={(event) => formik?.handleChange(event)}
												/>
												<div className="py-2.5 px-5 text-gray-22 border-left">
													<SypacText
														variant="overline-normal-large"
														className="flex"
													>
														<p>
															{product.countryCode === 'MDA' ? 'MDL' : 'PLN'}
														</p>
													</SypacText>
												</div>
											</div>
											{formik.touched.pricePerUnit &&
											formik.errors.pricePerUnit ? (
												<span className="bottom-helper">
													<T keyName="selectProduct.errorPricePerUnit">
														{formik.errors.pricePerUnit}
													</T>
												</span>
											) : null}
										</SypacInput>
									</div>
								</div>
							</div>
						</div>

						<div className="flex justify-center w-full py-5 gap-5">
							<div />
							<SypacButton
								variant="secondary"
								size="small"
								className="w-full"
								onClick={onClose}
							>
								<button
									className="px-10 py-2.5 hover:border-gray-2 w-full"
									type="reset"
								>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="modalConfirmationCode.cancel">Cancel</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									type="submit"
									className={
										'px-15 py-2.5 border-primary-violet hover:border-kimberly w-full'
									}
									disabled={disableSend}
								>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="modalManageProduct.save">Save</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<div />
						</div>
					</form>
				) : (
					<div className="w-full flex flex-row justify-center items-center pb-5 min-w-[400px] min-h-[400px]">
						<SypacText className="m-auto w-max italic">
							<span>Loading...</span>
						</SypacText>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ManageProduct;
