import React from 'react';
import { SypacBox, SypacText } from '@sypac/component-library-react';
import dayjs from 'dayjs';
import {
	ProducerBillingStatisticProps,
	StatisticCardProps,
} from './ProducerBillingStatistic.interface';
import { T } from '@tolgee/react';
import { NumericFormat } from 'react-number-format';

const ProducerBillingStatistic: React.FC<ProducerBillingStatisticProps> = ({
	amountPaid = '0',
	amountPending = '0',
	allOrders = '0',
	ordersLast30Days = '0',
	ordersLast60Days = '0',
	paidOrders = '0',
	orderInProgress = '0',
	ordersInDispute = '0',
	revenueLast30Days = '0',
	revenueLast60Days = '0',
	revenueLast90Days = '0',
	paymentTerm = dayjs().toISOString(),
	nextInvoicingDate = dayjs().toISOString(),
	countryCode,
}) => {
	const StatisticCard = ({
		keyName,
		label,
		value,
		borderColor,
		isDoubleHeight = false,
	}: StatisticCardProps) => (
		<div
			className={`flex px-5 rounded-10 border border-solid box-border ${
				isDoubleHeight
					? 'flex-col justify-center gap-[6px] h-[80px]'
					: 'flex-row gap-[14px] items-center h-[44px]'
			} ${borderColor}`}
		>
			<SypacText variant="overline-regular-large">
				<p className="text-gray-40">
					<T keyName={keyName}>{label}</T>
				</p>
			</SypacText>
			<SypacText variant={isDoubleHeight ? 'body-regular-large' : 'heading-5'}>
				<p className="text-gray-80 font-medium">{value}</p>
			</SypacText>
		</div>
	);

	return (
		<div className="relative w-full">
			<SypacBox hover={false} boxStyle="solid" boxColor="primary">
				<div className="p-5">
					<div className="flex gap-[60px]">
						<div className="flex flex-col gap-[10px]">
							<SypacText variant="overline-regular-large">
								<p className="text-sm text-gray-40">
									<T keyName="producerBillingStatistic.eardnedFrom">
										Earned from
									</T>{' '}
									{dayjs(nextInvoicingDate)
										.subtract(+paymentTerm, 'days')
										.format('DD MMM. YYYY')}{' '}
									<T keyName="producerBillingStatistic.to">to</T>{' '}
									{dayjs(nextInvoicingDate).format('DD MMM. YYYY')}
								</p>
							</SypacText>

							<div className="flex gap-5">
								<SypacText variant="heading-4">
									<p className="text-xl text-gray-80 whitespace-nowrap">
										<NumericFormat
											type="text"
											displayType="text"
											thousandSeparator="."
											decimalSeparator=","
											value={parseFloat(amountPaid)}
											suffix={countryCode === 'MD' ? ' MDL' : ' zł'}
										/>
									</p>
								</SypacText>

								<div className="flex w-full items-center justify-center bg-gray-10/50 border border-solid border-gray-10 rounded-full px-2.5">
									<SypacText variant="overline-regular-medium">
										<p className="text-sm text-gray-80">
											<T keyName="producerBillingStatistic.paymentScheduledFor">
												Payment scheduled for
											</T>{' '}
											{dayjs(nextInvoicingDate).format('DD MMM')}
										</p>
									</SypacText>
								</div>
							</div>
						</div>

						<div className="border-0 border-solid border-l-[1px] border-gray-10" />

						<div className="flex flex-col gap-[10px]">
							<SypacText variant="overline-regular-large">
								<p className="text-sm text-gray-40">
									<T keyName="producerBillingStatistic.bondBalance">
										Bond balance:
									</T>
								</p>
							</SypacText>

							<SypacText variant="heading-4">
								<p className="text-xl text-gray-80">
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={parseFloat(amountPending)}
										suffix={countryCode === 'MD' ? ' MDL' : ' zł'}
									/>
								</p>
							</SypacText>
						</div>
					</div>
				</div>

				<div className="border-0 border-solid border-t-[1px] border-gray-10" />

				<div className="p-5">
					<div className="grid grid-cols-3 gap-4">
						<StatisticCard
							keyName="producerBillingStatistic.paidOrders"
							label="Paid orders:"
							value={paidOrders}
							borderColor="border-mountain-meadow"
						/>
						<StatisticCard
							keyName="producerBillingStatistic.orderInProgress"
							label="Order in progress:"
							value={orderInProgress}
							borderColor="border-texas-rose"
						/>
						<StatisticCard
							keyName="producerBillingStatistic.ordersInDispute"
							label="Orders in dispute:"
							value={ordersInDispute}
							borderColor="border-red"
						/>
						<StatisticCard
							keyName="producerBillingStatistic.allOrders"
							label="All orders:"
							value={allOrders}
							borderColor="border-gray-10"
						/>
						<StatisticCard
							keyName="producerBillingStatistic.orderLast30Days"
							label="Order last 30 days:"
							value={ordersLast30Days}
							borderColor="border-gray-10"
						/>
						<StatisticCard
							keyName="producerBillingStatistic.orderLast60Days"
							label="Order last 60 days:"
							value={ordersLast60Days}
							borderColor="border-gray-10"
						/>
						<StatisticCard
							keyName="producerBillingStatistic.revenueLast30"
							label="Revenue last 30 d.:"
							value={revenueLast30Days}
							borderColor="border-gray-10"
							isDoubleHeight
						/>
						<StatisticCard
							keyName="producerBillingStatistic.revenueLast60"
							label="Revenue last 60 d.:"
							value={revenueLast60Days}
							borderColor="border-gray-10"
							isDoubleHeight
						/>
						<StatisticCard
							keyName="producerBillingStatistic.revenueLast90"
							label="Revenue last 90 d.:"
							value={revenueLast90Days}
							borderColor="border-gray-10"
							isDoubleHeight
						/>
					</div>
				</div>
			</SypacBox>
		</div>
	);
};

export default ProducerBillingStatistic;
