/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	SypacButton,
	SypacDropdown,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';

import { useGetCompanyById } from '../../../../hooks/use-get-company-by-id';
import { BankDetailsProps, CompanyBankDetails } from './BankDetails.interface';
import SuccessScreen from '../SuccessScreen/SuccessScreen';
import { CompanyService } from '../../../../services/company.services';
import { T, useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import LoadingSpinner from '../../../../assets/LoadingSpinner';

const paymentTerms = [
	{ value: 0, label: '0 days' },
	{ value: 5, label: '5 days' },
	{ value: 7, label: '7 days' },
	{ value: 10, label: '10 days' },
	{ value: 14, label: '14 days' },
	{ value: 30, label: '30 days' },
	{ value: 60, label: '60 days' },
	{ value: 90, label: '90 days' },
	{ value: 120, label: '120 days' },
	{ value: 180, label: '180 days' },
	{ value: 365, label: '365 days' },
];

const BankDetails = ({ companyId, onBack, onClose }: BankDetailsProps) => {
	const { t } = useTranslate();
	const [successScreen, setSuccessScreen] = useState(false);
	const [isReqLoading, setIsReqLoading] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [company, _, isLoading] = useGetCompanyById({
		id: companyId,
	});
	const isCustomer = company?.companyTypes?.includes('customer');

	const getCurrencyLabel = (countryCode: string) =>
		countryCode === 'MD' ? 'MDL' : 'zl';
	const creditLimits = [
		{ value: 0, label: `0 ${getCurrencyLabel(company?.countryCode!)}` },
		{
			value: 10000,
			label: `10 000 ${getCurrencyLabel(company?.countryCode!)}`,
		},
		{
			value: 100000,
			label: `100 000 ${getCurrencyLabel(company?.countryCode!)}`,
		},
		{
			value: 500000,
			label: `500 000 ${getCurrencyLabel(company?.countryCode!)}`,
		},
	];

	const formik = useFormik({
		initialValues: {
			bankAccountNumber: company?.bankDetails?.bankAccountNumber,
			swift: company?.bankDetails?.swift,
			bankName: company?.bankDetails?.bankName,
			iban: company?.bankDetails?.iban,
			creditLimit: isCustomer ? company?.bankDetails?.creditLimit : 0,
			paymentTerm: company?.bankDetails?.paymentTerm,
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			setIsReqLoading(true);
			try {
				const bankDetails: CompanyBankDetails = {
					bankAccountNumber: values.bankAccountNumber,
					bankName: values.bankName,
					swift: values.swift || '',
					iban: values.iban || '',
					creditLimit: Number(values.creditLimit),
					paymentTerm: Number(values.paymentTerm),
				};

				await CompanyService.companyVerificationStep4(companyId, {
					...bankDetails,
				});
			} catch (e: any) {
			} finally {
				setIsReqLoading(false);
				setSuccessScreen(true);
			}
		},
		validationSchema: Yup.object({
			bankAccountNumber: Yup.string().required(
				t('bankDetails.bankAccountNumber', 'Bank account number is required'),
			),
			bankName: Yup.string().required(
				t('bankDetails.bankNameIsRequired', 'Bank name is required'),
			),
			swift: Yup.string().required(
				t('bankDetails.swiftIsRequired', 'Swift is required'),
			),
			iban: Yup.string().required(
				t('bankDetails.ibanIsRequired', 'Iban is required'),
			),
			paymentTerm: Yup.string().required(
				isCustomer
					? t('bankDetails.paymentTermIsRequired', 'Payment Term is required')
					: t(
							'bankDetails.paymentTermIsRequired',
							'Withdraw intervals is required',
					  ),
			),
			creditLimit: Yup.string().required(
				t('bankDetails.creditLimitIsRequired', 'Credit limit is required'),
			),
		}),
	});

	const onSelectDropdown = (e: any, id: string) => {
		formik.setFieldValue(`${id}`, e?.detail?.value);
	};

	return successScreen ? (
		<SuccessScreen onClose={onClose} />
	) : isLoading ? (
		<></>
	) : (
		<form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
			<div className="flex flex-col px-10 py-5 gap-6">
				<div className="flex justify-between items-center pb-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90 -tracking-[0.01em]">
							<T keyName="bankDetails.bankDetails">Bank details</T>
						</p>
					</SypacText>
				</div>
				<div className="grid grid-cols-2 gap-4">
					<SypacInput
						error={
							!!(
								formik.touched.bankAccountNumber &&
								formik.errors.bankAccountNumber
							)
						}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="bankDetails.bankAccountNumber">Bank Account Number</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="bankAccountNumber"
							type="text"
							placeholder={t(
								'bankDetails.enterBankAccountNumber',
								'Enter Bank Account Number',
							)}
							value={formik.values.bankAccountNumber}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik.touched.bankAccountNumber &&
						formik.errors.bankAccountNumber ? (
							<span className="input-error">
								{formik.errors.bankAccountNumber}
							</span>
						) : null}
					</SypacInput>
					<SypacInput error={!!(formik.touched.swift && formik.errors.swift)}>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="bankDetails.swift">SWIFT</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="swift"
							type="text"
							placeholder={t('bankDetails.enterSwift', 'Enter SWIFT')}
							value={formik.values.swift}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik.touched.swift && formik.errors.swift ? (
							<span className="input-error">{formik.errors.swift}</span>
						) : null}
					</SypacInput>
					<SypacInput
						error={!!(formik.touched.bankName && formik.errors.bankName)}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="bankDetails.bankName">Bank Name</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="bankName"
							type="text"
							placeholder={t('bankDetails.enterBankName', 'Enter Bank Name')}
							value={formik.values.bankName}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik.touched.bankName && formik.errors.bankName ? (
							<span className="input-error">{formik.errors.bankName}</span>
						) : null}
					</SypacInput>
					<SypacInput error={!!(formik.touched.iban && formik.errors.iban)}>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="bankDetails.iban">IBAN</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="iban"
							type="text"
							placeholder={t('bankDetails.enterIban', 'Enter IBAN')}
							value={formik.values.iban}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik.touched.iban && formik.errors.iban ? (
							<span className="input-error">{formik.errors.iban}</span>
						) : null}
					</SypacInput>
				</div>
				<div
					className={`grid ${
						isCustomer ? 'grid-cols-2' : 'grid-cols-1'
					} gap-4 pb-5`}
				>
					{isCustomer ? (
						<div className="bg-blue-10 rounded-10 p-3">
							<SypacInput
								error={
									!!(formik.touched.creditLimit && formik.errors.creditLimit)
								}
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="bankDetails.creditLimit">Credit limit</T>
								</label>
								<SypacDropdown
									name="creditLimit"
									placeholder={t(
										'bankDetails.selectCreditLimit',
										'Select Credit limit',
									)}
									callback={(e) => onSelectDropdown(e, 'creditLimit')}
									className={classNames({
										'border_required': !!(
											formik.touched.creditLimit && formik.errors.creditLimit
										),
									})}
								>
									{creditLimits?.map(({ value, label }) => {
										return (
											// @ts-ignore
											<sypac-dropdown-option
												key={Math.random() + '-' + value}
												value={value}
												label={label}
												selected={value == formik.values.creditLimit}
											/>
										);
									})}
								</SypacDropdown>
								{formik.touched.creditLimit && formik.errors.creditLimit ? (
									<span className="input-error">
										{formik.errors.creditLimit}
									</span>
								) : null}
							</SypacInput>
						</div>
					) : null}
					<div className="bg-black-squeeze rounded-10 p-3">
						<SypacInput
							error={
								!!(formik.touched.paymentTerm && formik.errors.paymentTerm)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								{isCustomer
									? t('bankDetails.paymentTerm', 'Payment term')
									: t('bankDetails.withdraw', 'Withdraw')}
							</label>
							<SypacDropdown
								name="paymentTerm"
								placeholder={
									isCustomer
										? t('bankDetails.selectPaymentTerm', 'Select Payment term')
										: t(
												'bankDetails.selectWithdrawIntervals',
												'Select withdraw intervals',
										  )
								}
								callback={(e) => onSelectDropdown(e, 'paymentTerm')}
								className={classNames({
									'border_required': !!(
										formik.touched.paymentTerm && formik.errors.paymentTerm
									),
								})}
							>
								{paymentTerms
									.filter(({ value }) => !(value === 7 && isCustomer))
									.map(({ value, label }) => {
										return (
											// @ts-ignore
											<sypac-dropdown-option
												key={Math.random() + '-' + value}
												value={value}
												label={label}
												selected={value == formik.values.paymentTerm}
											/>
										);
									})}
							</SypacDropdown>
							{formik.touched.paymentTerm && formik.errors.paymentTerm ? (
								<span className="input-error">{formik.errors.paymentTerm}</span>
							) : null}
						</SypacInput>
					</div>
				</div>
			</div>
			<div className="flex justify-center w-full py-5 gap-6 border-0 border-t border-solid border-gray-10">
				<SypacButton variant="secondary" size="small">
					<button className="px-6 py-3" onClick={onBack}>
						<SypacText variant="body-regular-medium">
							<p>
								<T keyName="bankDetails.back">Back</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
				<SypacButton variant="primary" size="small">
					<button className="px-6 py-3" type="submit">
						<SypacText
							variant="body-regular-medium"
							className="flex items-center gap-4"
						>
							<p>
								<T keyName="bankDetails.completeVerification">
									Complete verification
								</T>
							</p>
							{isReqLoading ? <LoadingSpinner /> : null}
						</SypacText>
					</button>
				</SypacButton>
			</div>
		</form>
	);
};

export default BankDetails;
