import React, { useMemo, useState } from 'react';
import { ProductStatusEnum } from '../../../../../../interfaces/product.interface';
import { PendingBadge } from '../../../../../../assets/PendingBadge';
import {
	SypacAvatar,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import CircleValid from '../../../../../../assets/CircleValid';
import CircleClose from '../../../../../../assets/CircleClose';
import { RejectBadge } from '../../../../../../assets/RejectBadge';
import dayjs from 'dayjs';
import Flag from 'react-world-flags';
import Map from '../../../../../../components/Map/Map';
import { NumericFormat } from 'react-number-format';
import ProductEdit from './ProductEdit';
import {
	InfoRowProps,
	ProductOverviewProps,
} from '../ProductDetails.interface';
import TableStatus from '../../../../../../components/TableStatus/TableStatus';
import Close from '../../../../../../assets/Close';

const ProductOverview: React.FC<ProductOverviewProps> = ({
	product,
	approveProduct,
	rejectProduct,
	editProduct,
}) => {
	const [readOnly, setReadOnly] = useState<boolean>(true);
	const [openedMap, setOpenMap] = useState<boolean>(false);

	const status: string = useMemo(
		() => product?.status || ProductStatusEnum.PENDING,
		[product?.status],
	);
	const productType = useMemo(() => {
		return `${product?.type || ''} ${
			product?.size ? `(${product?.size})` : ''
		}`;
	}, [product?.type, product?.size]);

	const openMap = () => {
		setOpenMap(true);
	};

	const InfoRow = ({
		label,
		value,
		isBadge = false,
		badgeContent,
		className = '',
	}: InfoRowProps) => (
		<div
			className={`grid grid-cols-[185px,auto] gap-3 items-center ${className}`}
		>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40">{label}</p>
			</SypacText>
			{isBadge ? (
				badgeContent
			) : (
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{value}</p>
				</SypacText>
			)}
		</div>
	);

	return (
		<>
			{product.status === 'pending' && readOnly ? (
				<div className="border border-solid border-texas-rose bg-texas-rose-10 rounded-10 mb-5">
					<div className="flex flex-row p-5">
						<div>
							<PendingBadge />
						</div>
						<div className="flex flex-col ml-6">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-90">
									<T keyName="productDetails.productVerificationPending">
										Product verification pending
									</T>
								</p>
							</SypacText>
							<SypacText variant="body-regular-smal" className="mt-2">
								<p className="text-gray-40">
									<T keyName="productDetails.productVerificationPendingDescription">
										Click "Approve product" to publish it in the SYPAC Store or
										click "Reject product" to remove this product from the
										"Pending approval" list.
									</T>
								</p>
							</SypacText>
							<div className="gap-3 mt-4 flex flex-row">
								<SypacButton variant="secondary" size="small">
									<button
										className="px-5 py-[5px] border border-solid border-mountain-meadow"
										onClick={approveProduct}
									>
										<CircleValid />
										<SypacText variant="body-regular-medium" className="ml-1">
											<p className="text-mountain-meadow">
												<T keyName="productDetails.approveProduct">
													Approve product
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<SypacButton variant="secondary" size="small">
									<button
										className="px-5 py-[5px] border border-solid border-red"
										onClick={rejectProduct}
									>
										<CircleClose />
										<SypacText variant="body-regular-medium" className="ml-1">
											<p className="text-red">
												<T keyName="productDetails.rejectProduct">
													Reject product
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						</div>
					</div>
				</div>
			) : null}
			{product.status === 'rejected' && readOnly ? (
				<div className="border border-solid border-red bg-red-10 rounded-10 mb-5">
					<div className="flex flex-row p-5">
						<div>
							<RejectBadge />
						</div>
						<div className="flex flex-col ml-6">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-90">
									<T keyName="productDetails.productVerificationRejected">
										Product verification rejected
									</T>
								</p>
							</SypacText>
							<SypacText variant="body-regular-smal" className="mt-2">
								<p className="text-gray-40">
									<T keyName="productDetails.productVerificationRejectedDescription">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua.
									</T>
								</p>
							</SypacText>
						</div>
					</div>
				</div>
			) : null}

			{readOnly ? (
				<div className="flex flex-col gap-5">
					<div className="flex border-0 border-b border-t border-solid border-gray-10">
						<div className="w-full flex items-center justify-between px-10 py-[10px]">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-40">
									<T keyName="productDetails.productInformation">
										Product information
									</T>
								</p>
							</SypacText>

							<SypacButton variant="secondary" size="small">
								<button
									type="button"
									className="p-0 border-0 group"
									onClick={() => setReadOnly(false)}
								>
									<span className="flex m-0 scale-[1.3] mr-[2px]">
										<SypacIcon
											iconName="Document Add"
											size="custom"
											width="32px"
											height="32px"
											className="text-primary-violet transition group-hover:text-primary-violet/70"
										/>
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-primary-violet transition group-hover:text-primary-violet/70">
											<T keyName="productDetails.edit">Edit product</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>
					<div className="flex flex-col gap-[14px] px-10 pb-5 border-0 border-b border-solid border-gray-10">
						<SypacText variant="body-regular-medium" className="mb-[6px]">
							<p className="text-gray-80">
								<T keyName="productDetails.generalInformation">
									General information
								</T>
							</p>
						</SypacText>
						<InfoRow
							label={<T keyName="productDetails.productName">Product name:</T>}
							value={product.name}
						/>
						<InfoRow
							label={<T keyName="productDetails.productType">Product type:</T>}
							value={product?.type ? productType : '—'}
						/>
						<InfoRow
							label={<T keyName="productDetails.status">Status:</T>}
							isBadge
							badgeContent={
								<TableStatus
									state={status}
									isPartial={false}
									insideOut={true}
								/>
							}
						/>
						<InfoRow
							label={<T keyName="productDetails.postedBy">Posted by:</T>}
							value={
								<div className="h-[22px] flex items-center">
									<SypacAvatar initials={product?.company?.name} size="sm" />
									<div className="flex ml-5">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">{product?.company?.name}</p>
										</SypacText>
									</div>
								</div>
							}
						/>
						<InfoRow
							label={<T keyName="productDetails.createdDate">Created date:</T>}
							value={dayjs(product.createdAt).format('DD MMM. YYYY')}
						/>
						<InfoRow
							label={<T keyName="productDetails.productId">Product ID:</T>}
							value={product.id}
						/>
						<InfoRow
							label={
								<T keyName="productDetails.storeLocation">Store location:</T>
							}
							value={
								<div className="h-[22px] flex items-center">
									<div className="flex items-center gap-4">
										<Flag
											className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
											code={product.countryCode}
											width={22}
											height={16}
										/>
										<SypacText variant="body-regular-medium">
											<p className="max-w-[262px] truncate">
												{product.address}
											</p>
										</SypacText>
									</div>

									<SypacButton
										variant="secondary"
										size="small"
										className="ml-auto group"
									>
										<button
											type="button"
											className="p-0 border-0 w-max"
											onClick={openMap}
										>
											<SypacIcon
												iconName="Eye"
												size="custom"
												width="24px"
												height="24px"
												className="text-primary-violet transition group-hover:text-primary-violet/70"
											/>
											<SypacText variant="body-regular-medium">
												<p className="text-primary-violet transition group-hover:text-primary-violet/70">
													<T keyName="productDetails.viewOnMap">View on map</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>
								</div>
							}
						/>

						{openedMap ? (
							<div className="relative h-[323px] flex flex-col mt-[6px] border border-solid border-gray-10 rounded-10">
								<SypacButton variant="subTitle">
									<button
										type="button"
										className="absolute right-[10px] top-[10px] flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-80 hover:border-[2px] rounded-lg z-[100]"
										onClick={() => setOpenMap(false)}
									>
										<Close width="10" height="10" />
									</button>
								</SypacButton>
								<Map
									markers={[
										{
											latitude: product.location?.coordinates[1]!,
											longitude: product.location?.coordinates[0]!,
											variant: 'product',
											radius: {
												value: 100,
												isAdjustable: false,
											},
											pickup: {
												pickupAddress: product.address,
												pickupCountryCode: product.countryCode!,
											},
										},
									]}
								/>
								<div className="absolute w-full bottom-0 flex items-center gap-3 px-[10px] py-[11px] bg-alabaster border-0 border-t border-solid border-gray-10 rounded-b-10 z-[100] box-border">
									<div
										className={`w-[14px] h-[14px] rounded-[3px] ${
											product.unlimited ? 'bg-mountain-meadow' : 'bg-red'
										}`}
									/>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">Delivery area: 100km</p>
									</SypacText>
								</div>
							</div>
						) : null}
					</div>

					<div className="flex flex-col gap-[14px] px-10">
						<SypacText variant="body-regular-medium" className="mb-[6px]">
							<p className="text-gray-80">
								<T keyName="productDetails.priceAvailability">
									Price & availability
								</T>
							</p>
						</SypacText>
						<InfoRow
							label={
								<T keyName="productDetails.originalPrice">
									Original price (per ton):
								</T>
							}
							value={
								<div className="flex items-center gap-4">
									<Flag
										className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
										code={product.countryCode}
										width={22}
										height={16}
									/>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseFloat(
													product?.pricePerUnit?.toFixed(2) || '0',
												)}
												suffix={product.currency === 'lei' ? ' MDL' : ' zł'}
											/>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<T keyName="productDetails.perTon"> — per ton</T>
										</p>
									</SypacText>
								</div>
							}
						/>
						<InfoRow
							label={
								<T keyName="productDetails.productStock">Product stock:</T>
							}
							value={
								<div className="flex gap-1 items-center">
									<div
										className={`h-2 w-2 mr-1 rounded-sm ${
											product.unlimited ? 'bg-mountain-meadow' : 'bg-red'
										}`}
									/>
									{product.unlimited ? (
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">
												<T keyName="productDetails.available">Available</T>
											</p>
										</SypacText>
									) : (
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">—</p>
										</SypacText>
									)}
								</div>
							}
						/>
						<InfoRow
							label={
								<T keyName="productDetails.availableQuantity">
									Available quantity:
								</T>
							}
							value={
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										{product.availableQuantity > 0 ? (
											<>
												{product.availableQuantity}{' '}
												<T keyName="productDetails.tons">tons</T>
											</>
										) : (
											'—'
										)}
									</p>
								</SypacText>
							}
						/>
						<InfoRow
							label={<T keyName="productDetails.markup">Markup:</T>}
							value={
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										{product.markup ? <>{product.markup} %</> : '—'}
									</p>
								</SypacText>
							}
						/>
						<InfoRow
							label={
								<T keyName="productDetails.availabilityRadius">
									Availability radius:
								</T>
							}
							value={'100 km'}
						/>
						<InfoRow
							label={<T keyName="productDetails.vat">VAT %</T>}
							value={
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										{product.vat ? <>{product.vat} %</> : '—'}
									</p>
								</SypacText>
							}
						/>
						<InfoRow
							label={
								<T keyName="productDetails.priceInStore">
									Price in store (per ton):
								</T>
							}
							value={
								<div className="flex items-center">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={
													product.pricePerUnitWithProcent
														? parseFloat(
																product.pricePerUnitWithProcent.toFixed(2),
														  )
														: 0
												}
												suffix={product.currency === 'lei' ? ' MDL' : ' zł'}
											/>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<T keyName="productDetails.perTon"> — per ton</T>
										</p>
									</SypacText>
								</div>
							}
						/>
					</div>
				</div>
			) : (
				<div className="px-10">
					<ProductEdit
						onClose={() => setReadOnly(true)}
						product={product}
						editProduct={(data) => {
							editProduct(data);
							setReadOnly(true);
						}}
					/>
				</div>
			)}
		</>
	);
};

export default ProductOverview;
