import React, { useEffect, useState } from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';
import { InvoiceLineTableInterface } from './interfaces/InvoiceLineTable.interface';
import { InvoiceLineInterface } from '../PaymentsTable/interfaces/Payment.interface';
import { TableStatusEnum } from '../TableStatus/TableStatus.enum';
import dayjs from 'dayjs';
import TableStatus from '../TableStatus/TableStatus';
import { NumericFormat } from 'react-number-format';
import { T, useTranslate } from '@tolgee/react';
import NoResults from './components/NoResults';

export const InvoiceLinesTable = ({
	rows,
	rowClick,
	search,
	target,
	currentTab,
}: InvoiceLineTableInterface) => {
	const { t } = useTranslate();
	const [keyRows, setRows] = useState<InvoiceLineInterface[]>([]);

	useEffect(() => {
		const newRows = rows
			? rows?.map((row: InvoiceLineInterface) => ({
					...row,
					key: row?.id,
			  }))
			: [];
		setRows(newRows);
	}, [rows]);

	const getTrProps = (
		record: InvoiceLineInterface,
		_index: number,
		_indent: number,
	): string => {
		// const baseClass =
		// 	record.id === selectedOrderId
		// 		? `payment-table-row-selected payment-table-row-${record.id}`
		// 		: `payment-table-row-${record.id}`;
		// const isOverdue =
		// 	record.status === PaymentStatusEnum.PENDING &&
		// 	dayjs(record.dueDate).toISOString() < dayjs().toISOString();
		// const status = isOverdue ? PaymentStatusEnum.OVERDUE : record.status;
		// const bgClass = ['customer'].includes(target)
		// 	? ` payment-table-row-${status}`
		// 	: '';

		// return `${baseClass}${bgClass}`;
		return '';
	};

	const getColumns = () => {
		switch (target) {
			case 'producer':
				return columnsProducer(rowClick, search);
			default:
				return columnsTransporter(rowClick, search);
		}
	};
	const columnsTransporter = (
		rowClick: (payment: InvoiceLineInterface) => void,
		search?: string,
	) => [
		{
			title: t('invoiceLinesTableTransporter.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceLineInterface) {
				const isOverdue =
					value?.invoice?.status === TableStatusEnum.PENDING &&
					dayjs(value?.invoice?.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value?.invoice?.dueDate, 'day')
					: dayjs(value?.invoice?.dueDate).diff(dayjs(), 'day');
				const status = isOverdue
					? TableStatusEnum.OVERDUE
					: value?.invoice?.status;
				let isPartial =
					value?.invoice?.status === TableStatusEnum.PENDING &&
					!!value?.invoice?.payments?.length;
				isPartial =
					value?.invoice?.status === TableStatusEnum.PAID
						? value?.invoice?.payments?.length > 0
						: isPartial;

				return (
					<div
						className="flex items-center justify-start h-full w-fit py-4 px-3"
						onClick={() => rowClick(value)}
					>
						<TableStatus
							days={days}
							state={status ? status : ''}
							isPartial={isPartial}
							insideOut={true}
						/>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableTransporter.createdDate', 'Created'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceLineInterface) {
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.amountOpen', 'Amount open'),
			dataIndex: '',
			className: 'text-left',
			key: 'totalAmount',
			render(value: InvoiceLineInterface) {
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value?.lineTotal?.toFixed(2) || '0')}
									suffix={value?.invoice?.countryCode === 'MD' ? ' MDL' : ' zł'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableTransporter.productName', 'Product name'),
			dataIndex: '',
			className: 'text-left',
			key: 'description',
			render(value: InvoiceLineInterface) {
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								<T keyName="invoiceLinesTable.transportService">
									Transport service
								</T>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableTransporter.dispatcher', 'Dispatcher'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceLineInterface) {
				const user = value?.order?.assigneeUser;

				return user && user.name ? (
					<div
						className="flex gap-4 items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacAvatar size="sm" initials={user.email || user?.name}>
							{user?.avatarUrl ? (
								<img src={user?.avatarUrl} alt={user.email || user?.name} />
							) : null}
						</SypacAvatar>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{user.name}</p>
						</SypacText>
					</div>
				) : null;
			},
		},
	];

	const columnsProducer = (
		rowClick: (payment: InvoiceLineInterface) => void,
		search?: string,
	) => [
		{
			title: t('invoiceLinesTableProducer.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceLineInterface) {
				const isOverdue =
					value?.invoice?.status === TableStatusEnum.PENDING &&
					dayjs(value?.invoice?.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value?.invoice?.dueDate, 'day')
					: dayjs(value?.invoice?.dueDate).diff(dayjs(), 'day');
				const status = isOverdue
					? TableStatusEnum.OVERDUE
					: value?.invoice?.status;
				let isPartial =
					value?.invoice?.status === TableStatusEnum.PENDING &&
					!!value?.invoice?.payments?.length;
				isPartial =
					value?.invoice?.status === TableStatusEnum.PAID
						? value?.invoice?.payments?.length > 0
						: isPartial;

				return (
					<div
						className="flex items-center justify-start h-full w-fit py-4 px-3"
						onClick={() => rowClick(value)}
					>
						<TableStatus
							days={days}
							state={status ? status : ''}
							isPartial={isPartial}
							insideOut={true}
						/>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableProducer.createdDate', 'Created'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceLineInterface) {
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.amountOpen', 'Amount open'),
			dataIndex: '',
			className: 'text-left',
			key: 'totalAmount',
			render(value: InvoiceLineInterface) {
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value?.lineTotal?.toFixed(2) || '0')}
									suffix={value?.invoice?.countryCode === 'MD' ? ' MDL' : ' zł'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableProducer.productName', 'Product name'),
			dataIndex: '',
			className: 'text-left',
			key: 'description',
			render(value: InvoiceLineInterface) {
				return (
					<div
						className="flex items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.description}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('invoiceLinesTableProducer.user', 'User'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceLineInterface) {
				const user = value?.order?.assigneeUser;
				return user && user.name ? (
					<div
						className="flex gap-4 items-center h-full px-3"
						onClick={() => rowClick(value)}
					>
						<SypacAvatar size="sm" initials={user.email || user?.name}>
							{user?.avatarUrl ? (
								<img src={user?.avatarUrl} alt={user.email || user?.name} />
							) : null}
						</SypacAvatar>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{user.name}</p>
						</SypacText>
					</div>
				) : null;
			},
		},
	];

	return (
		<Table
			className={`w-full payment-table ${rows.length === 0 ? 'h-full' : ''} ${
				target === 'producer'
					? 'payment-table-producer'
					: 'payment-table-transporter'
			}`}
			columns={getColumns()}
			data={keyRows}
			rowClassName={getTrProps}
			emptyText={<NoResults currentTab={currentTab!} />}
		/>
	);
};
