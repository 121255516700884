import { ProductInterface } from '../../interfaces/product.interface';

export type LatLng = {
	lat: number;
	lng: number;
};

export type DeliveryMarkers = {
	deliveryAddress: string;
	deliveryCountryCode: string;
};

export type PickupMarkers = {
	pickupAddress: string;
	pickupCountryCode: string;
};

export type FleetBaseMarkers = {
	address: string | undefined;
	countryCode: string | undefined;
};

export type MapPolyline = {
	id: number;
	distance: number;
	trafficTime: number;
	coordinates: LatLng[];
	orderRoute: boolean;
};

export type MapMarker = {
	latitude: number;
	longitude: number;
	variant: string;
	truck?: FleetDto;
	delivery?: DeliveryMarkers;
	pickup?: PickupMarkers;
	fleetBase?: FleetBaseMarkers;
	radius?: {
		value: number | undefined;
		isAdjustable: boolean;
	};
	orderProduct?: ProductInterface;
};

export interface FleetDto {
	id: number;
	name: string;
	type: string;
	truckModel: string;
	truckYear: number;
	truckColor: string;
	truckNumber: string;
	truckPhoto?: string | undefined;
	driverFullName?: string | undefined;
	driverAvatar?: string | undefined;
	createdAt: string;
	updatedAt: string;
	companyId: number;
	code: string;
	driverEmail?: string | undefined;
	driverPhone?: string | undefined;
	licensePlates?: string | undefined;
	emissionType?: string | undefined;
	truckBrand: string;
	uid: string;
	status: string;
	lastAddress?: string;
	lastLocation?: {
		type: string;
		coordinates: number[];
	};
}

export interface MapProps {
	orderStatus?: string;
	polygons?: MapPolyline[];
	markers?: MapMarker[];
	markerHovered?: string;
	currentMarker?: MapMarker;
	target?: string;
	setBaseRadius?: (radius: number) => void;
}

export interface MapView {
	coords: LatLng;
}

export interface PinContentProps {
	countryCode?: string;
	address?: string;
	orderProduct?: ProductInterface;
}

export interface MarkerContentProps {
	marker: MapMarker;
	delivery: DeliveryMarkers | undefined;
	pickup: PickupMarkers | undefined;
	fleetBase: FleetBaseMarkers | undefined;
	orderProduct: ProductInterface | undefined;
}

export interface MarkerIconProps {
	fleetBase: any;
	orderProduct: any;
	markerHovered: string;
	variant: PinVariant;
	status?: string;
}

export interface RadiusCircleProps {
	location: { latitude: number; longitude: number };
	radius?: number;
}

export interface GroupedMarkers {
	[key: string]: MapMarker[];
}

export interface ZoomLevel {
	[key: number]: number;
}

export enum PinVariant {
	truck = 'truck',
	pickup = 'pickup',
	delivery = 'delivery',
	product = 'product',
	fleet_base = 'fleet_base',
}

export enum RouteColorVariant {
	orderRoute = '#20AC93',
	toPickup = '#FFB352',
	completed = '#494C83',
	customer = '#373A77',
}

export const StatusTruckPinColors: {
	[key: string]: string;
} = {
	available: '#20AC93',
	not_available: '#FFB352',
};
