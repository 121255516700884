import React from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import './companies_table.css';
import { CompaniesTableInterface } from './interfaces/CompaniesTable.interface';
import { CompanyShort } from '../../services/company.services';
import { RenderIconByStaus } from './RenderIconByStatus';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';
import Flag from 'react-world-flags';
import { countries } from '../../assets/countries.constant';
import dayjs from 'dayjs';
import AvatarDropDown from '../AvatarDropDown/AvatarDropDown';
import { useTranslate } from '@tolgee/react';

export const CompaniesTable: React.FC<CompaniesTableInterface> = ({
	rows,
	onClick,
	selectedCompany,
	search,
}) => {
	const keyRows = rows?.map((row) => ({ ...row, key: row?.id }));
	const { t } = useTranslate();
	const columns = (search?: string) => [
		{
			title: t('companies_table.state', 'State'),
			dataIndex: '',
			className: 'text-center',
			width: 50,
			key: 'status',
			render(value: CompanyShort) {
				return (
					<div
						className="w-full flex justify-center"
						onClick={() => onClick(value)}
					>
						<RenderIconByStaus status={value.status} />
					</div>
				);
			},
		},
		{
			title: t('companies_table.id_nr', 'ID Number'),
			dataIndex: '',
			className: 'text-left',
			width: 30,
			key: 'status',
			render(value: CompanyShort) {
				const htmlId = search
					? value?.id
							?.toString()
							.replace(search, `<span class="text-kimberly">${search}</span>`)
					: value?.id?.toString();

				return (
					<div
						className="flex justify-start items-center h-full p-4 cursor-pointer"
						onClick={() => onClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p
								className="text-gray-80"
								dangerouslySetInnerHTML={{ __html: htmlId! }}
							/>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companies_table.country', 'Country'),
			dataIndex: '',
			className: 'text-left',
			key: 'countryCode',
			width: 50,
			render(value: CompanyShort) {
				return (
					<div
						className="flex justify-start items-center h-full p-4"
						onClick={() => onClick(value)}
					>
						<Flag
							code={value?.countryCode?.toLocaleUpperCase()}
							width={24}
							height={16}
						/>
						<SypacText variant="body-regular-medium" className="ml-2">
							<p className="text-gray-80">
								{countries[value?.countryCode?.toLocaleUpperCase()]}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companies_table.registered', 'Registered'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			width: 50,
			render(value: CompanyShort) {
				return (
					<div
						className="flex justify-start items-center h-full p-4"
						onClick={() => onClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{dayjs(value.createdAt).format('DD MMM. YYYY')}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companies_table.company_name', 'Company Name'),
			dataIndex: '',
			className: 'text-left',
			key: 'name',
			width: 300,
			render(value: CompanyShort) {
				const htmlName = search
					? value?.name
							?.toString()
							.replace(search, `<span class="text-kimberly">${search}</span>`)
					: value?.name?.toString();

				return (
					<div
						className="flex justify-start items-center h-full p-4"
						onClick={() => onClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p
								className="text-gray-80"
								dangerouslySetInnerHTML={{ __html: htmlName! }}
							/>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companies_table.seen', 'Seen'),
			dataIndex: '',
			className: 'text-left',
			key: 'updatedAt',
			width: 20,
			render(value: CompanyShort) {
				return (
					<div
						className="flex justify-start items-center h-full p-4"
						onClick={() => onClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{dayjs(value.updatedAt).fromNow(true)}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companies_table.assigned_to', 'Assigned to'),
			dataIndex: '',
			className: 'text-left',
			key: 'assignee',
			width: 20,
			render(value: CompanyShort) {
				const isOneAssignee = value.assignees?.length === 1;
				const avatars = value?.assignees?.map((item) => ({
					id: item.id.toString(),
					avatarUrl: item.assigneeUser?.avatarUrl,
					fullName: item.assigneeUser?.name || '',
				}));
				const firstAssignee = value?.assignees?.at(0);
				return (
					<div
						className="flex justify-start items-center h-full p-4"
						onClick={() => onClick(value)}
					>
						{value?.assignees && value?.assignees?.length > 0 ? (
							<>
								{isOneAssignee ? (
									<div className="flex flex-row justify-start items-center">
										<SypacAvatar
											initials={firstAssignee?.assigneeUser?.name}
											isGrouped={true}
											key={1}
											size="md"
										>
											{firstAssignee?.assigneeUser?.avatarUrl ? (
												<img
													src={firstAssignee?.assigneeUser?.avatarUrl}
													alt={firstAssignee?.assigneeUser?.name}
												/>
											) : null}
										</SypacAvatar>
										<SypacText variant="body-regular-medium" className="ml-3">
											<p className="text-gray-80">
												{firstAssignee?.assigneeUser?.name}
											</p>
										</SypacText>
									</div>
								) : (
									<AvatarDropDown items={avatars!} />
								)}
							</>
						) : null}
					</div>
				);
			},
		},
	];

	const getTrProps = (
		record: CompanyShort,
		_index: number,
		_indent: number,
	): string => {
		return record.id === selectedCompany ? `user-table-row-selected` : '';
	};

	return (
		<>
			<Table
				className="h-full user-table"
				columns={columns(search)}
				rowClassName={getTrProps}
				data={keyRows}
				emptyText={() => {
					return <div></div>;
				}}
			/>
		</>
	);
};
